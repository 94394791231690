<script lang="ts" setup>
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'
import { TRPCClientError } from '@trpc/client'
type RouterOutput = inferRouterOutputs<AppRouter>
type ErrorOutput = TRPCClientError<AppRouter>
const { $client } = useNuxtApp()
const nuxtApp = useNuxtApp()
const paths = ref<Array<{ text: string, href: string }>>([])
const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
watch(nuxtApp.$router.currentRoute, async (newRoute) => {
  paths.value = []
  const { path, params } = newRoute
  let compoundLink: string = ''
  let prevPathParameter: string = ''
  for (const pathParameter of path.split('/').slice(1)) {
    compoundLink += `/${ pathParameter }`
    let formattedPathParam = ''
    const alwaysCapitalized = ['items', 'customizations', 'print-ticket']
    if (alwaysCapitalized.includes(pathParameter)) {
      formattedPathParam = pathParameter.split('-').map((p: string) => capitalize(p)).join(' ')
    }
    if (prevPathParameter === 'items') {
      // formattedPathParam = `<${ pathParameter }>`
      // formattedPathParam = await client.query('merchant.component.navigationBreadcrumb.itemTitle', pathParameter)
      formattedPathParam = await $client.merchant.component_navigationBreadcrumb_itemTitle.query(pathParameter)
    }
    if (prevPathParameter === 'customizations') {
      // formattedPathParam = `<${ pathParameter }>`
      // formattedPathParam = await client.query('merchant.component.navigationBreadcrumb.customizationTitle', pathParameter)
      formattedPathParam = await $client.merchant.component_navigationBreadcrumb_customizationTitle.query({
        customizationId: path.split('/')[4],
        itemId: compoundLink.split('/')[2],
      })
    }
    if (!formattedPathParam) {
      formattedPathParam = capitalize(pathParameter).replace('-', ' ')
    }
    paths.value.push({
      text: formattedPathParam as string,
      href: compoundLink as string,
    })
    prevPathParameter = pathParameter
  }
}, { immediate: true })
</script>
<template>
  <nav class="flex min-h-[2rem] select-none" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1 md:space-x-3">
      <li v-for="(path, pathIndex) in paths" class="inline-flex items-center">
        <div class="flex items-center">
          <svg v-if="pathIndex === 0" class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
          <svg v-if="path.href !== '/'" class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          <NuxtLink :to="path.href" class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2">
            {{ path.text }}
          </NuxtLink>
        </div>
      </li>
    </ol>
  </nav>
</template>