<script lang="ts" setup>
import {
  Home,
  Printer,
  Receipt,
  BarChart,
  LayoutGrid,
  SwatchBook,
  Bike,
  HandCoins,
  CalendarCheck,
  Cog,
  CreditCard,
  LogOut,
  ListChecks,
  Loader2,
  X,
  Menu,
} from 'lucide-vue-next';
type Icon = typeof Home
import { useNuxtApp } from '#app'
import {
  Disclosure,
} from '@headlessui/vue'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'
import { TRPCClientError } from '@trpc/client'
type RouterOutput = inferRouterOutputs<AppRouter>
type ErrorOutput = TRPCClientError<AppRouter>
const { $client, $router } = useNuxtApp()
const { data: settings, error } = await useAsyncData<RouterOutput['merchant']['navigation'], ErrorOutput>(() => $client.merchant.navigation.query(), { lazy: true })
const loadedHref = useUniversalUrl()
const currentHref = computed(() => {
  return $router.currentRoute.value.fullPath
})
watch(currentHref, (newVal) => {
  sidebarOpen.value = false
})
watch(error, (e) => {
  if (e) {
    if (e.data?.httpStatus === 401) {
      navigateTo('/sign-in?returnURL=' + loadedHref)
    }
    if (e.data?.httpStatus === 404) {
      navigateTo('/registration')
    }
  }
}, { immediate: true })
const { syncing, unsavedChanges } = usePageState()
const sidebarOpen = ref(false)
type Tab = {
  name: string
  icon: Icon
  href: string
  target?: string
}
const tabs: Ref<Array<Tab>> = ref([
  { name: 'Home', icon: Home, href: '/' },
  { name: 'Printer', icon: Printer, href: '/printer' },
  { name: 'Transactions', icon: Receipt, href: '/transactions/pages/1' },
  { name: 'Marketing & Analytics', icon: BarChart, href: '/marketing' },
  { name: 'Items', icon: LayoutGrid, href: '/items' },
  { name: 'Branding', icon: SwatchBook, href: '/branding' },
  { name: 'Payments & Tipping', icon: HandCoins, href: '/payments' },
  { name: 'Logistics', icon: Bike, href: '/logistics' },
  { name: 'Schedule', icon: CalendarCheck, href: '/schedule' },
  { name: 'Advanced', icon: Cog, href: '/advanced' },
  { name: 'Billing', icon: CreditCard, href: '/billing' },
  { name: 'Sign Out', icon: LogOut, href:'/sign-out' },
])
const impersonationUserTarget = useCookie('impersonationUserTarget')
const signOut = () => {
  impersonationUserTarget.value = ''
  // $router.push({ path: '/developer/merchants' })
}
const checklistComplete = ref(true)
onMounted(async() => {
  checklistComplete.value = (await useMerchantGoingLiveChecklist()).checklistComplete
  if (!checklistComplete.value) {
    tabs.value.unshift({ name: 'Going Live Checklist', icon: ListChecks, href: '/going-live-checklist' },)
  }
})
const relativeUrlToFirstPath = (relativeUrl: string) => ('/' + relativeUrl.split('/')[1])
const config = useRuntimeConfig()
</script>
<template>
  <div>
    <div v-if="settings">
      <Title>{{ config.public.BRAND_NAME }}</Title>
      <Link rel="icon" type="image/png" sizes="32x32" :href="`${ config.public.FAVICON_URL }?crop=entropy&ar=1:1&fit=crop&h=32&auto=compress&fm=png`"/>
      <Link rel="icon" type="image/png" sizes="16x16" :href="`${ config.public.FAVICON_URL }?crop=entropy&ar=1:1&fit=crop&h=16&auto=compress&fm=png`"/>
      <div class="z-[1000] fixed bottom-0 left-0 inline-block rounded-tr-xl text-white bg-indigo-600 px-4 py-.5 animate-pulse" v-if="impersonationUserTarget">
        Impersonating {{ impersonationUserTarget }} <span class="ml-4 cursor-pointer hover:underline" @click="signOut()">Sign out</span>
      </div>
      <div class="h-full dsv1__Page">
        <div>
          <Disclosure as="header" class="dsv1__ToolbarNavigation z-10" v-slot="{ open }">
            <div class="absolute z-[100] top-2 right-2 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
              <button type="button" class="h-14 w-14 inline-flex items-center justify-center dsv1__PrimaryButton" @click="sidebarOpen = true">
                <span class="sr-only">Open sidebar</span>
                <Menu class="inline-block" :size="25"/>
              </button>
            </div>
          </Disclosure>
        </div>
        <TransitionRoot v-if="tabs.length" as="template" :show="sidebarOpen">
          <Dialog as="div" class="fixed inset-0 flex z-40 md:hidden" @close="sidebarOpen = false">
            <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75" />
            </TransitionChild>
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <div class="dsv1__Page relative flex-1 flex flex-col max-w-xs w-full dsv1__Navigation">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button type="button" class="ml-1 flex items-center justify-center dsv1__SecondaryButton" @click="sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <X class="inline-block text-white" :size="25"/>
                    </button>
                  </div>
                </TransitionChild>
                <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div class="flex-shrink-0 flex items-center px-4">
                    <ClientOnly>
                      <NuxtLink to="/">
                        <img class="h-8 w-auto" src="/logo.png" />
                      </NuxtLink>
                      <template #fallback>
                        <div class="h-8 w-auto"/>
                      </template>
                    </ClientOnly>
                  </div>
                  <nav v-auto-animate class="mt-5 px-2 space-y-1">
                    <NuxtLink v-for="item in tabs" :key="item.name" :to="item.href" :class="[(item.href === relativeUrlToFirstPath(currentHref)) ? 'dsv1__Navigation__NavigationItem__current' : '', 'dsv1__Navigation__NavigationItem']">
                      <component
                        :size="24"
                        :strokeWidth="1"
                        :is="item.icon"
                        :class="[(item.href === relativeUrlToFirstPath(currentHref)) ? 'text-white' : 'group-hover:text-gray-300', 'mr-4 flex-shrink-0']"
                      />
                      {{ item.name }}
                    </NuxtLink>
                  </nav>
                </div>
                <div class="dsv1__Navigation__NavigationUser">
                  <a href="#" class="flex-shrink-0 group block">
                    <div class="flex items-center">
                      <!-- <div>
                        <img v-if="favicon" class="inline-block h-10 w-10" :src="favicon" />
                      </div> -->
                      <div class="ml-3">
                        <div class="dsv1__Navigation__NavigationUser__NavigationUserOrg">
                          {{ settings.brandName }}
                        </div>
                        <div class="dsv1__Navigation__NavigationUser__NavigationUserEmail">
                          {{ settings.email }}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 w-14">
              <!-- Force sidebar to shrink to fit close icon -->
            </div>
          </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div v-if="tabs.length" class="z-30 hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 select-none">
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex-1 flex flex-col min-h-0 dsv1__Navigation">
            <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div class="flex items-center flex-shrink-0 px-4">
                <ClientOnly>
                  <NuxtLink to="/">
                    <img class="h-8 w-auto" src="/logo.png" />
                  </NuxtLink>
                  <template #fallback>
                    <div class="h-8 w-auto"/>
                  </template>
                </ClientOnly>
              </div>
              <nav v-auto-animate class="mt-5 flex-1 px-2 space-y-1">
                <NuxtLink v-for="item in tabs" :key="item.name" :to="item.href" :class="[(item.href === relativeUrlToFirstPath(currentHref)) ? 'dsv1__Navigation__NavigationItem__current' : '', 'dsv1__Navigation__NavigationItem']">
                  <component
                    :size="24"
                    :strokeWidth="1"
                    :is="item.icon"
                    :class="[(item.href === relativeUrlToFirstPath(currentHref)) ? 'text-white' : 'group-hover:text-gray-300', 'mr-4 flex-shrink-0']"
                  />
                  {{ item.name }}
                </NuxtLink>
              </nav>
            </div>
            <div class="dsv1__Navigation__NavigationUser">
              <div class="flex-shrink-0 w-full group block">
                <div class="flex items-center">
                  <!-- <div>
                    <img v-if="favicon" class="inline-block h-10 w-10" :src="favicon" />
                  </div> -->
                  <div class="ml-3">
                    <div class="dsv1__Navigation__NavigationUser__NavigationUserOrg">
                      {{ settings.brandName }}
                    </div>
                      <div class="dsv1__Navigation__NavigationUser__NavigationUserEmail">
                        <Transition>
                          <span v-if="settings.email">{{ settings.email }}</span>
                        </Transition>
                        <span v-if="!settings.email">&nbsp;</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="md:pl-64 flex flex-col flex-1" :class="{ 'md:pl-0': !tabs.length }">
          <main class="flex-1">
            <div class="py-6">
              <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <!-- Replace with your content -->
                <div class="py-4">
                  <div class="min-h-[90vh] dsv1__Page">
                    <Breadcrumb class="mb-4"/>
                    <NuxtPage />
                  </div>
                  <!-- <ItemsPlaceholder v-if="initialLoading"/> -->
                  <div class="flex flex-wrap justify-center mt-6">
                    <div class="max-w-xl w-full">
                      <!-- <BrandingPlaceholder v-if="initialLoading"/> -->
                    </div>
                  </div>
                  <!-- <div class="border-4 border-dashed border-gray-200 rounded-lg h-96" /> -->
                </div>
                <!-- /End replace -->
              </div>
            </div>
          </main>
        </div>
      </div>
      <div v-if="unsavedChanges && !syncing" style="z-index: 1000" class="bg-gray-800 text-white fixed top-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 px-3 rounded">
        Page has unsaved changes
      </div>
      <div v-if="syncing" class="bg-gray-800 text-white z-40 fixed top-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 px-3 rounded">
        <Loader2 class="mr-2 inline-block spinning-icon" :size="16"/>
        Syncing...
      </div>
      <!-- <div v-if="!checklistComplete" class="z-40 bg-white rounded border-[#2463eb] border-[1rem] fixed text-xl top-4 right-16 px-6 py-4 font-bold shadow-lg px-3">
        <a href="/going-live-checklist" class="dsv1__Link select-none">
          <ListChecks class="mr-2 inline-block" />
          Going live checklist
        </a>
      </div> -->
    </div>
  </div>
</template>
<style lang="scss">
.ais-Highlight {
  display: block;
}
select option {
  @apply text-black font-sans;
}
.quick-spin {
  -webkit-animation: fa-spin .5s infinite linear;
  animation: fa-spin .5s infinite linear;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>